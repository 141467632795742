import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

import Fade from "react-reveal/Fade";
import { useLax } from "use-lax";

import Layout from "../../components/Layout";
import Hero from "../../components/Hero";
import IntroImage from "../../components/IntroImage";
import Button from "../../components/Button";
import Cookies from "js-cookie";

const IndexPage = props => {
  const location = props.location;
  const lang = "ja";

  // Set language to cookie
  Cookies.remove("selectLang");
  Cookies.set("selectLang", "ja");

  // Page setting
  const pageSlug = "home";
  const subTitle = "信州伊那谷にある築160年の古民家";

  const data = useStaticQuery(graphql`
    query {
      historyImage1: file(relativePath: { eq: "home/img_history_01.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage2: file(relativePath: { eq: "home/img_history_02.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage3: file(relativePath: { eq: "home/img_history_03.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage4: file(relativePath: { eq: "home/img_history_04.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_1: file(relativePath: { eq: "home/img_history_05_1.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage5_2: file(relativePath: { eq: "home/img_history_05_2.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      historyImage6: file(relativePath: { eq: "home/img_history_06.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  // Initialize lax
  useLax({
    breakpoints: { small: 0, large: 768 },
  });

  return (
    <Layout
      location={location}
      pageSlug={pageSlug}
      lang={lang}
      subTitle={subTitle}
    >
      <Hero lang={lang} />

      <div className="section-wrap">
        <section id="intro" className="section-intro">
          <Fade>
            <div className="container-sm">
              <span className="intro__head--caption">ABOUT</span>
              <h2 className="intro__head">箕澤屋を残すために。</h2>
            </div>
          </Fade>
          <Fade>
            <div
              className="intro__text container-sm lax"
              data-lax-opacity_small="250 0, 350 1"
              data-lax-opacity_large="500 0, 600 1"
            >
              <p>箕澤屋(みさわや)は、信州伊那谷にある築160年の古民家です。</p>
              <p>
                江戸末期に建てられたこの家は、かつての豪農の屋敷。
                <br className="only-for-medium" />
                立派な梁と囲炉裏が残り、文化的な価値のある貴重な建物です。
              </p>
              <p>
                この家を残したいと願うたくさんの方々の想いをつなぐべく、
                <br className="only-for-medium" />
                約40年間、人の住んでいなかったこの家を改修し、人が集える場所へと整備しました。
              </p>
            </div>
          </Fade>
        </section>

        <section className="section-bg">
          <Fade>
            <IntroImage />
          </Fade>
        </section>

        <section id="history" className="section-history">
          <div className="history__inner container-sm">
            <div className="period__wrap">
              <Fade left distance={"20px"} delay={100}>
                <h3 className="history__head lax">HISTORY</h3>
              </Fade>
              <div className="period__bg"></div>
              <div className="period period-01">
                <div
                  className="period1 period__vertical lax"
                  data-lax-opacity_small="750 0, 850 1"
                  data-lax-opacity_large="1450 0, 1550 1"
                >
                  江戸末期
                </div>
                <div
                  className="period__text lax"
                  data-lax-opacity_small="850 0, 950 1"
                  data-lax-opacity_large="1450 0, 1550 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">箕澤屋建築</h4>
                  </div>
                  <div className="period__text--description">
                    小原家「箕澤屋」が火災により焼失。
                    <br />
                    今の基礎となる建物へと再建築される。
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="1050 0, 1150 1"
                  data-lax-translate-y_small="1000 0, 2000 -50"
                  data-lax-opacity_large="1500 0, 1600 1"
                  data-lax-translate-y_large="1500 0, 2500 -50"
                >
                  <Img
                    fluid={data.historyImage1.childImageSharp.fluid}
                    className="period__image__img"
                    alt="箕澤屋建築"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="950 0, 1150 1"
                  data-lax-translate-y_small="950 0, 1950 50"
                  data-lax-opacity_large="1600 0, 1700 1"
                  data-lax-translate-y_large="1500 0, 2500 50"
                >
                  1861
                </div>
              </div>

              <div className="period period-02">
                <div
                  className="period__vertical period__vertical--meiji lax"
                  data-lax-opacity_small="1250 0, 1350 1"
                  data-lax-opacity_large="1800 0, 1900 1"
                >
                  明治
                </div>
                <div
                  className="period__vertical period__vertical--taisho lax"
                  data-lax-opacity_small="1450 0, 1550 1"
                  data-lax-opacity_large="1950 0, 2050 1"
                >
                  大正
                </div>
                <div
                  className="period__text lax"
                  data-lax-opacity_small="1250 0, 1350 1"
                  data-lax-opacity_large="1750 0, 1850 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">小原家繁栄時代</h4>
                  </div>
                  <div className="period__text--description">
                    江戸〜明治にかけて、さまざまな事業を手掛け資産家となった小原家。明治初期に、地ビール製造なども行うが、規制の影響等により数年で廃業。
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="1450 0, 1550 1"
                  data-lax-translate-y_small="1450 0, 2450 -50"
                  data-lax-opacity_large="1850 0, 1950 1"
                  data-lax-translate-y_large="1850 0, 2850 -50"
                >
                  <Img
                    fluid={data.historyImage2.childImageSharp.fluid}
                    className="period__image__img"
                    alt="小原家繁栄時代"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="1350 0, 1450 1"
                  data-lax-translate-y_small="1400 0, 2400 50"
                  data-lax-opacity_large="1900 0, 2000 1"
                  data-lax-translate-y_large="1900 0, 2900 50"
                >
                  1900
                </div>
              </div>

              <div className="period period-03">
                <div
                  className="period__vertical lax"
                  data-lax-opacity_small="1650 0, 1750 1"
                  data-lax-opacity_large="2350 0, 2450 1"
                >
                  昭和
                </div>
                <div
                  className="period__text lax"
                  data-lax-opacity_small="1600 0, 1700 1"
                  data-lax-opacity_large="2200 0, 2300 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">無人となる</h4>
                  </div>
                  <div className="period__text--description">
                    最後まで家を守ってきた三代前儀十郎の妻、ちか江が病により転居し、箕澤屋は無人となる。
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="1850 0, 1950 1"
                  data-lax-translate-y_small="1450 0, 2450 -50"
                  data-lax-opacity_large="2400 0, 2500 1"
                  data-lax-translate-y_large="2400 0, 3400 -50"
                >
                  <Img
                    fluid={data.historyImage3.childImageSharp.fluid}
                    className="period__image__img"
                    alt="無人となる"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="1700 0, 1800 1"
                  data-lax-translate-y_small="1700 0, 2700 50"
                  data-lax-opacity_large="2300 0, 2400 1"
                  data-lax-translate-y_large="2250 0, 3250 50"
                >
                  1976
                </div>
              </div>

              <div className="period period-04">
                <div
                  className="period__vertical lax"
                  data-lax-opacity_small="2100 0, 2200 1"
                  data-lax-opacity_large="2900 0, 3000 1"
                >
                  平成
                </div>
                <div
                  className="period__text lax"
                  data-lax-opacity_small="1950 0, 2050 1"
                  data-lax-opacity_large="2800 0, 2900 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">箕澤屋を守る会発足</h4>
                  </div>
                  <div className="period__text--description">
                    相続した親族が町に寄贈を申し出たことをきっかけに、保存を望む有志が集まり「信州箕澤屋を守る会」を発足。公開イベント等を実施。
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="2200 0, 2300 1"
                  data-lax-translate-y_small="2200 0, 3200 -50"
                  data-lax-opacity_large="3000 0, 3100 1"
                  data-lax-translate-y_large="3000 0, 4000 -50"
                >
                  <Img
                    fluid={data.historyImage4.childImageSharp.fluid}
                    className="period__image__img"
                    alt="箕澤屋を守る会発足"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="2100 0, 2200 1"
                  data-lax-translate-y_small="2100 0, 3100 50"
                  data-lax-opacity_large="3100 0, 3200 1"
                  data-lax-translate-y_large="3100 0, 4100 50"
                >
                  1997
                </div>
              </div>

              <div className="period period-05">
                <div
                  className="period__text lax"
                  data-lax-opacity_small="2300 0, 2400 1"
                  data-lax-opacity_large="3200 0, 3300 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">現メンバー引継</h4>
                  </div>
                  <div className="period__text--description">
                    高齢化した守る会のメンバーから継続困難という話を受け、現メンバーが箕澤屋を引継ぐ。建物維持のため、補修工事を行う。
                  </div>
                </div>
                <div
                  className="period__image period__image--1 lax"
                  data-lax-opacity_small="2500 0, 2600 1"
                  data-lax-translate-y_small="2500 0, 3500 -20"
                  data-lax-opacity_large="3450 0, 3550 1"
                  data-lax-translate-y_large="3450 0, 4450 -20"
                >
                  <Img
                    fluid={data.historyImage5_1.childImageSharp.fluid}
                    className="period__image__img"
                    alt="現メンバー引継1"
                  />
                </div>
                <div
                  className="period__image period__image--2 lax"
                  data-lax-opacity_small="2600 0, 2700 1"
                  data-lax-translate-y_small="2600 0, 3600 -50"
                  data-lax-opacity_large="3600 0, 3700 1"
                  data-lax-translate-y_large="3600 0, 4600 -80"
                >
                  <Img
                    fluid={data.historyImage5_2.childImageSharp.fluid}
                    className="period__image__img"
                    alt="現メンバー引継2"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="2400 0, 2500 1"
                  data-lax-translate-y_small="2400 0, 3400 50"
                  data-lax-opacity_large="3450 0, 3550 1"
                  data-lax-translate-y_large="3450 0, 4550 50"
                >
                  2014
                </div>
              </div>

              <div className="period period-06">
                <div
                  className="period__vertical lax"
                  data-lax-opacity_small="2900 0, 3000 1"
                  data-lax-opacity_large="3950 0, 4050 1"
                >
                  令和
                </div>
                <div
                  className="period__text lax"
                  data-lax-opacity_small="2800 0, 2900 1"
                  data-lax-opacity_large="3850 0, 3950 1"
                >
                  <div className="period__text--head">
                    <div className="head-circle">
                      <div className="circle__outer pointOut"></div>
                      <div className="circle__inner"></div>
                    </div>
                    <span className="head-line"></span>
                    <h4 className="head-copy">新プロジェクト開始</h4>
                  </div>
                  <div className="period__text--description">
                    箕澤屋に惹き寄せられ集まった有志メンバーにより、夏季を中心にイベントなどの新たな保存プロジェクトがスタート。
                  </div>
                </div>
                <div
                  className="period__image lax"
                  data-lax-opacity_small="2900 0, 3000 1"
                  data-lax-translate-y_small="2900 0, 3900 -50"
                  data-lax-opacity_large="3950 0, 4050 1"
                  data-lax-translate-y_large="3950 0, 4950 -80"
                >
                  <Img
                    fluid={data.historyImage6.childImageSharp.fluid}
                    className="period__image__img"
                    alt="新プロジェクト開始"
                  />
                </div>
                <div
                  className="period__year lax"
                  data-lax-opacity_small="2900 0, 3000 1"
                  data-lax-translate-y_small="2900 0, 3900 50"
                  data-lax-opacity_large="3950 0, 4050 1"
                  data-lax-translate-y_large="3950 0, 4950 50"
                >
                  2017
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="projects" className="section-projects">
          <div className="projects__intro__wrap">
            <div className="container-sm">
              <Fade>
                <div className="projects__intro">
                  <span className="projects__intro__head--caption">
                    PROJECTS
                  </span>
                  <h2 className="projects__intro__head">保存活動について</h2>
                  <div className="projects__intro__text">
                    <p>
                      2016年の改修後から、保存を目的に、さまざまなイベントを開催してきました。2020年のパンデミックをきっかけに、イベント自体のあり方を考え直し、現在は、持続可能な形での活動をメンバー中心に行っています。
                    </p>
                    <p>
                      ※2022年現在、公開イベント、及び夏季営業の予定はありません。なお、活動やメンバーについては、
                      <a href="#newsletter" className="color-link">
                        ニュースレター
                      </a>
                      にて月1程度でお届けしております。
                    </p>
                  </div>
                </div>

                <div className="projects__button">
                  <a
                    href="/jp/projects/"
                    className="blog__button__link nohover"
                  >
                    <Button text={"Projects"} icon={"ar"} />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </section>

        <section id="blog" className="section-blog">
          <div className="blog__content container-sm">
            <h2 className="blog__head">
              箕澤屋のこと、
              <br className="only-for-small" />
              もっと知りたい方は
            </h2>
            <p>
              2014年に引き継いでからのリノベーションやその他のことは、管理人ブログもどうぞ。
            </p>
            <div className="blog__button">
              <a
                href="https://blog.misawaya.org/"
                className="blog__button__link nohover"
              >
                <Button text={"Read Blog"} icon={"ar"} />
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
